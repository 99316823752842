<template>
  <div class="members__item">
    <span v-if="unreadFeed"
      class="message_badge"
      :class="getClassBadgeLength(unreadFeed.cnt)"
      title="Unread comments"
    >{{unreadFeed.cnt}}</span>
    <div class="members__head">
      <div class="members__avatar">
        <div v-if="user.avatar"
          class="avatar"
          :style="'background-image: url(' +
              user.avatar +
              ')'"></div>
        <div v-else
          class="avatar"
          :style="'background-image: url(' +
              require('@/assets/images/avatar.svg') +
              ')'"></div>
      </div>
      <div v-if="user.friend" class="members__email">
        <router-link :to="{name: 'MessagesPage', params: { id: user.id }}">
          <span class="icon-friend-email"></span>
        </router-link>
      </div>
    </div>
    <div class="members__name">{{user.name}}</div>
    <div class="members__actions">
      <a v-if="(type == 'member' || type == 'admin' || type == 'search') && user.friend"
       class="members__btn-primary"
       href=""
       @click.prevent="onUnfriend">Unfriend
      </a>
      <a v-else-if="(type == 'member' || type == 'admin' || type == 'search') && !user.friend"
       class="members__btn-primary"
       href=""
       @click.prevent="onFriend">Add friend
      </a>
      <a v-if="(role === 'admin') && (type == 'member' || type == 'search')"
        class="members__btn-secondary"
        href=""
        @click.prevent="onKickout">Kick out
      </a>
      <a v-if="(role === 'admin') && type == 'request'"
        class="members__btn-primary"
        href=""
        @click.prevent="onAccept">Accept
      </a>
      <a v-if="(role === 'admin') && type == 'request'"
        class="members__btn-secondary"
        href=""
        @click.prevent="onDeny">Deny
      </a>
    </div>
  </div>
</template>
<script>
import badge from '@/mixins/badge';

export default {
  name: 'CardMember',
  components: {},
  mixins: [badge],
  props: {
    user: {
      type: Object,
      required: true,
    },
    role: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'member',
    },
    unreadFeed: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    onKickout() {
      this.$emit('kickout', this.user.id);
    },
    onUnfriend() {
      this.$emit('unfriend', this.user.id);
    },
    onFriend() {
      this.$emit('friend', this.user.id);
    },
    onAccept() {
      this.$emit('accept', this.user.id);
    },
    onDeny() {
      this.$emit('deny', this.user.id);
    },
  },
};
</script>
<style lang="scss" scoped>
.message_badge {
  &.two {
    width: 30px;
    border-radius: 40%;
  }

  &.three {
    width: 40px;
    border-radius: 40%;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;

  position: absolute;
  top: -10px;
  right: -10px;

  background-color: red;
  border-radius: 50%;

  margin-left: 10px;

  z-index: 1;

  color: #fff;
  font-size: 12px;
  font-weight: 600;

  @media (max-width: 992px) {
    right: -20px;
  }
}
</style>

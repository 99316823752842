import axios from '@/libs/axios';

export default {
  /**
   * Send request for get group by id
   * @param {Object} data
   * @returns {Object}
   */
  async actionUser(data) {
    const res = await axios.post('friends', data);
    return res;
  },

  /**
   * Send request for get group by id
   * @param {Object} data
   * @returns {Object}
   */
  async get(data) {
    const res = await axios.get('friends', { params: data });
    return res;
  },

  /**
   * Send request for get friends by params
   * @param {Object} params
   * @returns {Object}
   */
  async getFriendsByParams(params) {
    const res = await axios.get('invite/friends', { params });
    return res;
  },

  /**
   * Send request for invite user to friends
   * @param {Object} data
   * @returns {Object}
   */
  async invite(data) {
    const res = await axios.post('friends', data);
    return res;
  },
};

<template>
  <div>
    <div :id="slug" class="members__title">{{title}}</div>
    <div v-if="text && alert" class="container_alert">
      <Alert :variant="variant" v-if="text">{{ text }}</Alert>
    </div>
    <div class="members__list">
      <CardMember v-for="user in users"
        :key="user.id + getRandomKey(100, 10000000)"
        :user="user"
        :role="role"
        :type="type"
        :unreadFeed="unreadMessages.find((us) => us.id === user.id)"
        @kickout="onShowModal(user.id, 'modalKickout', 'revoke')"
        @unfriend="onShowModal(user.id, 'modalUnfriend', 'revoked')"
        @friend="onShowModal(user.id, 'modalFriend', 'sent')"
        @accept="onShowModal(user.id, 'modalAccept', 'accept')"
        @deny="onShowModal(user.id, 'modalDeny', 'deny')"
      />
    </div>
<!--    <Pagination  v-if="pagination.pageCount > 1"-->
<!--      :pagination="pagination"-->
<!--      v-on:changepage="getUsers" />-->
  </div>
</template>
<script>
import GroupService from '@/services/GroupService';
import Alert from '@/components/Alert.vue';
import CardMember from '@/views/profile/MyGroups/Group/Components/CardMember.vue';
// import Pagination from '@/views/profile/MyGroups/Pagination.vue';

import random from '@/mixins/random';

export default {
  name: 'ListUsers',
  mixins: [random],
  components: {
    CardMember,
    // Pagination,
    Alert,
  },
  props: {
    users: {
      type: Array,
      required: true,
    },
    role: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'member',
    },
    title: {
      type: String,
      default: '',
    },
    pagination: {
      type: Object,
      required: true,
    },
    alert: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: '',
    },
    searchText: {
      type: String,
    },
    unreadMessages: {
      type: Array,
    },
  },
  data() {
    return {
      groupID: this.$route.params.id,
      userID: undefined,
      usersData: null,
      paginationData: null,
      slug: this.title.toLowerCase().replace(' ', '_'),
    };
  },
  methods: {
    onShowModal(userID, modalName, action) {
      this.$emit('showModal', userID, modalName, this.type, action);

      document.getElementById(this.slug).scrollIntoView({ block: 'start', behavior: 'smooth' });
    },
    async getUsers() {
      let res = null;
      const data = {
        filter: this.type,
        page: this.$route.query.page || 1,
        'per-page': this.type === 'member' ? 6 : 3,
      };

      if (this.type === 'search') {
        data.filter = 'all';
        data.name = this.searchText;
        data['per-page'] = 15;
      }

      try {
        res = await GroupService.getUsersGroup(this.groupID, data);

        const paginationData = {
          pageCurrent: res.headers['x-pagination-current-page'],
          pageCount: +res.headers['x-pagination-page-count'] || 1,
          perPage: res.headers['x-pagination-per-page'],
          total: +res.headers['x-pagination-total-count'] || 7,
        };

        this.$emit('changeUsers', res.data, paginationData, this.type);
      } catch (e) {
        console.log({ textArg: e.response.data[0].message, variantArg: 'danger' });
        this.users = [];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
</style>

<template>
  <div v-if="group.role" class="container">
    <GroupRequest v-if="group.type === 'Private'
      && (group.role === 'guest' || group.role === 'request')"
                  :group="group"
    />
    <GroupHidden v-else-if="group.role === 'hidden'" :group="group"/>
    <div v-else class="user-cabinet__inner">
      <div v-if="group.role === 'guest' || group.role === 'request'" class="members__area">
        You should join this group to see the list of members
      </div>
      <div v-else-if="searchMembers" class="members__area">
        <ListUsers
          :users="users.search"
          :role="role"
          type="search"
          title="Search"
          :alert="alert.search"
          :text="text"
          :variant="variant"
          :search-text="searchText"
          :pagination="pagination.search"
          :unreadMessages="unreadMembers"
          @showModal="onShowModal"
          @changeUsers="onChangeUsers"
        />
      </div>
      <div v-else class="members__area">
        <ListUsers
          v-if="users.request && users.request.length"
          :users="users.request"
          :role="role"
          type="request"
          title="Joining Requests"
          :alert="alert.request"
          :text="text"
          :variant="variant"
          :pagination="pagination.request"
          :unreadMessages="unreadMembers"
          @showModal="onShowModal"
          @changeUsers="onChangeUsers"
        />
        <ListUsers
          v-if="users.admin && users.admin.length"
          :users="users.admin"
          :role="role"
          type="admin"
          title="Admins"
          :alert="alert.admin"
          :text="text"
          :variant="variant"
          :pagination="pagination.admin"
          :unreadMessages="unreadMembers"
          @showModal="onShowModal"
          @changeUsers="onChangeUsers"
        />
        <ListUsers
          v-if="users.member && users.member.length"
          :users="users.member"
          :role="role"
          type="member"
          title="Group Members"
          :alert="alert.member"
          :text="text"
          :variant="variant"
          :pagination="pagination.member"
          :unreadMessages="unreadMembers"
          @showModal="onShowModal"
          @changeUsers="onChangeUsers"
        />
      </div>
      <Modal
        @close="hideModal('modalKickout')"
        id="modalKickout"
        title=""
        buttonTextOk="Kick out"
        buttonVariantOk="danger"
        :showButtonCancel="true"
        @cancel="hideModal('modalKickout')"
        @ok="onActionUser"
      >
        <p>{{ modalText.kickout }}</p>
      </Modal>
      <Modal
        @close="hideModal('modalUnfriend')"
        id="modalUnfriend"
        title="Unfriend"
        buttonTextOk="Unfriend"
        buttonVariantOk="danger"
        :showButtonCancel="true"
        @cancel="hideModal('modalUnfriend')"
        @ok="onActionFriend"
      >
        <p>Are you sure?</p>
      </Modal>
      <Modal
        @close="hideModal('modalFriend')"
        id="modalFriend"
        title="Friend"
        buttonTextOk="Friend"
        buttonVariantOk="danger"
        :showButtonCancel="true"
        @cancel="hideModal('modalFriend')"
        @ok="onActionFriend"
      >
        <p>Are you sure?</p>
      </Modal>
      <Modal
        @close="hideModal('modalAccept')"
        id="modalAccept"
        title="Accept"
        buttonTextOk="Accept"
        buttonVariantOk="danger"
        :showButtonCancel="true"
        @cancel="hideModal('modalAccept')"
        @ok="onActionUser"
      >
        <p>Are you sure?</p>
      </Modal>
      <Modal
        @close="hideModal('modalDeny')"
        id="modalDeny"
        title="Deny"
        buttonTextOk="Deny"
        buttonVariantOk="danger"
        :showButtonCancel="true"
        @cancel="hideModal('modalDeny')"
        @ok="onActionUser"
      >
        <p>Are you sure?</p>
      </Modal>
    </div>
  </div>
</template>
<script>
import GroupService from '@/services/GroupService';
import FriendService from '@/services/FriendService';

import Modal from '@/components/Modal.vue';
import ListUsers from '@/views/profile/MyGroups/Group/Components/ListUsers.vue';
import GroupRequest from '@/views/profile/MyGroups/Group/Components/Feed/GroupRequest.vue';
import GroupHidden from '@/views/profile/MyGroups/Group/Components/Feed/GroupHidden.vue';

import modal from '@/mixins/modal';
import alert from '@/mixins/alert';
import content from '@/mixins/content';
import exception from '@/mixins/exception';
import websocket from '@/mixins/websocket';

export default {
  name: 'ProfileGroupMembers',
  mixins: [modal, alert, content, exception, websocket],
  components: {
    ListUsers,
    Modal,
    GroupRequest,
    GroupHidden,
  },
  props: {
    role: {
      type: String,
      default: '',
    },
    searchMembers: {
      type: Array,
      default: null,
    },
    searchPagination: {
      type: Object,
      default: null,
    },
    searchText: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      groupID: this.$route.params.id,
      group: {},
      userID: undefined,
      users: {
        request: null,
        admin: null,
        member: null,
        search: [],
      },
      pagination: {
        request: {
          pageCurrent: 1,
          pageCount: 1,
          perPage: 5,
          total: -1,
        },
        admin: {
          pageCurrent: 1,
          pageCount: 1,
          perPage: 5,
          total: -1,
        },
        member: {
          pageCurrent: 1,
          pageCount: 1,
          perPage: 5,
          total: -1,
        },
        search: {
          pageCurrent: 1,
          pageCount: 1,
          perPage: 15,
          total: 15,
        },
      },
      alert: {
        request: false,
        admin: false,
        member: false,
        search: false,
      },
      modalType: undefined,
      modalAction: undefined,
      modalName: undefined,
      searchUsersData: null,
      modalText: {
        kickout: 'Deleting user from the group is rather an exception.'
          + ' If you decided to do it without making a special agreement'
          + ' with this user, please make sure you used all the diplomatic'
          + ' methods to resolve the current issue. In any case, user will'
          + ' get authomated email message in case of deletion from the group.',
      },
      isFirstLoadUnreadMembers: true,
    };
  },
  async created() {
    this.group = await this.getGroup();

    if (this.group.id && !(this.group.role === 'guest' || this.group === 'request')) {
      this.users.request = await this.getUserBy('request');
      this.users.admin = await this.getUserBy('admin');
      this.users.member = await this.getUserBy('member');
    }

    this.subscribeToChannel('group-feed', this.setUnreadGroupFeed, this.group.id);
    this.$eventBus.$on('addedGroupMember', this.addMember);
    // this.$eventBus.$emit('setFriendsCount', 0, 'friends');
  },
  mounted() {
    window.addEventListener('scroll', async () => {
      const {
        scrollTop,
        scrollHeight,
        clientHeight,
      } = document.documentElement;
      const footer = document.getElementsByClassName('footer');
      if (window.innerWidth <= 768
        && scrollTop + clientHeight >= scrollHeight - footer[0].scrollHeight) {
        await this.getNextMembers();
      }
      if (window.innerWidth > 768 && scrollTop + clientHeight >= scrollHeight) {
        await this.getNextMembers();
      }
    });
  },
  computed: {
    me() {
      return this.$store.getters.getMe;
    },
    unreadMembers() {
      return this.$store.getters.getUnreadGroupMembers || [];
    },
  },
  methods: {
    async getGroup() {
      let res = null;

      try {
        res = await GroupService.group(this.groupID);

        const group = res.data.id ? res.data : this.group;
        this.$emit('setGroup', group);
        return group;
      } catch (e) {
        this.handleException(e);
        return {};
      }
    },
    onShowModal(userID, modalName, type, action) {
      this.userID = userID;
      this.modalType = type;
      this.modalAction = action;
      this.modalName = modalName;
      this.showModal(modalName);
    },

    async getNextMembers() {
      let res = null;
      if (this.pagination.member.pageCurrent < this.pagination.member.pageCount) {
        const pageCurrent = this.pagination.member.pageCurrent + 1;
        try {
          res = await GroupService.getUsersGroup(this.groupID, {
            filter: 'member',
            page: pageCurrent,
            'per-page': 6,
          });
          this.pagination.member = {
            pageCurrent: +res.headers['x-pagination-current-page'],
            pageCount: +res.headers['x-pagination-page-count'] || 1,
            perPage: res.headers['x-pagination-per-page'],
            total: +res.headers['x-pagination-total-count'] || 0,
          };
          this.isFirstLoadUnreadMembers = true;
          this.users.member.push(...res.data);
          this.onChangeUsers(res.data, this.pagination.member, 'member');
        } catch (e) {
          this.handleException(e);
          // this.users.member = [];
        }
      }
    },

    async allAdmins() {
      let res = null;
      const pageCurrent = this.pagination.admin.pageCurrent + 1;
      try {
        res = await GroupService.getUsersGroup(this.groupID, {
          filter: 'admin',
          page: pageCurrent,
          'per-page': 3,
        });
        this.pagination.admin = {
          pageCurrent: +res.headers['x-pagination-current-page'],
          pageCount: +res.headers['x-pagination-page-count'] || 1,
          perPage: res.headers['x-pagination-per-page'],
          total: +res.headers['x-pagination-total-count'] || 0,
        };
        this.isFirstLoadUnreadMembers = true;
        this.users.admin.push(...res.data);
        this.onChangeUsers(res.data, this.pagination.admin, 'admin');
        if (this.pagination.admin.pageCurrent < this.pagination.admin.pageCount) {
          await this.allAdmins();
        }
      } catch (e) {
        this.handleException(e);
        // this.users.member = [];
      }
    },
    async getUserBy(filter) {
      let res = null;
      try {
        res = await GroupService.getUsersGroup(this.groupID, {
          filter,
          page: this.pagination[filter].pageCurrent,
          'per-page': filter === 'member' ? 6 : 3,
        });

        this.pagination[filter] = {
          pageCurrent: +res.headers['x-pagination-current-page'],
          pageCount: +res.headers['x-pagination-page-count'] || 1,
          perPage: res.headers['x-pagination-per-page'],
          total: +res.headers['x-pagination-total-count'] || 0,
        };

        this.isFirstLoadUnreadMembers = true;
        if (filter === 'admin' && this.pagination.admin.pageCurrent < this.pagination.admin.pageCount) {
          this.allAdmins();
        }
        return res.data;
      } catch (e) {
        this.handleException(e);
        return [];
      }
    },
    async onActionFriend() {
      let res = null;
      try {
        res = await FriendService.actionUser({
          action: this.modalAction,
          user_id: this.userID,
        });

        if (this.modalAction === 'revoked') {
          this.users[this.modalType].find((user) => user.id === this.userID).friend = false;
        }

        this.showAndHideAlertSuccess(res.data.message);
      } catch (e) {
        this.handleException(e);
      }

      // document.getElementById(this.slug).scrollIntoView({ block: 'start', behavior: 'smooth' });
    },
    async onActionUser() {
      let res = null;
      try {
        res = await GroupService.actionUser(this.groupID, {
          action: this.modalAction,
          user_id: this.userID,
        });

        if (this.modalType !== 'search') {
          this.users[this.modalType] = await this.getUserBy(this.modalType);
        } else {
          this.users.search = await this.onSearch();
          this.group = await this.getGroup();
          this.$emit('changeCountUsers', this.group.cnt_user);
        }

        if (this.modalType === 'request' && this.modalAction === 'accept') {
          this.users.member = await this.getUserBy('member');
        }

        this.showAndHideAlertSuccess(res.data.message);
      } catch (e) {
        this.setAlert({ textArg: e.response.data[0].message, variantArg: 'danger' });
      }
    },
    async onSearch() {
      let res = null;
      try {
        res = await GroupService.getUsersGroup(this.groupID, {
          filter: 'all',
          name: this.searchText,
          page: 1,
          'per-page': 15,
        });

        this.pagination.search = {
          pageCurrent: res.headers['x-pagination-current-page'],
          pageCount: +res.headers['x-pagination-page-count'] || 1,
          perPage: res.headers['x-pagination-per-page'],
          total: +res.headers['x-pagination-total-count'] || 0,
        };

        return res.data;
      } catch (e) {
        console.log({ textArg: e.response.data[0].message, variantArg: 'danger' });
        return null;
      }
    },
    showAndHideAlertSuccess(message) {
      this.alert[this.modalType] = true;
      this.hideModal(this.modalName);
      this.setAlert({ textArg: message, variantArg: 'success' });

      setTimeout(() => {
        this.alert[this.modalType] = true;
        this.removeAlert();
      }, 5000);
    },
    onChangeUsers(users, pagination, type) {
      this.isFirstLoadUnreadMembers = true;

      this.$store.commit(
        'SET_UNREAD_GROUP_MEMBERS',
        [
          ...this.unreadMembers
            .filter((mem) => !this.users[type].map((member) => member.id).includes(mem.id)),
        ],
      );

      // this.users[type] = users;
      // this.pagination[type] = pagination;
    },
    setUnreadGroupFeed(event) {
      const data = JSON.parse(event.data).message;
      this.$eventBus.$emit('setFeedsCount', Object.keys(data.feed).length, 'feeds');
    },
    addMember(member) {
      this.isFirstLoadUnreadMembers = true;
      this.users.request.unshift(member);
      this.pagination.request.total += 1;

      if (this.users.request.length > 3) {
        this.users.request.pop();
        this.pagination.request.pageCount = Math.ceil(this.pagination.request.total / 3);
      }
    },
  },
  watch: {
    searchMembers(users) {
      this.users.search = users;
    },
    searchPagination(pagination) {
      this.pagination.search = pagination;
    },
    /* eslint-disable func-names */
    'pagination.admin': function (pagination) {
      if (this.pagination.member.total === -1) return;
      const count = pagination.total + this.pagination.member.total;
      this.$emit('changeCountUsers', count);
    },
    'pagination.member': function (pagination) {
      if (this.pagination.admin.total === -1) return;
      const count = pagination.total + this.pagination.admin.total;
      this.$emit('changeCountUsers', count);
    },
  },
  beforeDestroy() {
    this.$eventBus.$off('addedGroupMember', this.addMember);
    this.$store.commit('SET_UNREAD_GROUP_MEMBERS', []);
    window.removeEventListener('scroll', async () => {
      const {
        scrollTop,
        scrollHeight,
        clientHeight,
      } = document.documentElement;
      if (scrollTop + clientHeight >= scrollHeight) {
        await this.getNextMembers();
      }
    });
  },
};
</script>
